<template xmlns="http://www.w3.org/1999/html">
  <div v-if="!existsToken">
    <!-- <div class="language-div">
      <LanguageBar />
    </div> -->

    <div class="loginWrapper">
      <div class="sliderLogin">
        <!--Login box -->
        <div class="loginBox">
          <div class="navigationSlider">
            <a href="#" @click="selectLoginUser(false)" class="companiesTab" :class="{
              active: !loginUserSelected,
              'border-companies': !loginUserSelected
            }">{{ $t("title.companiesLabel") }}</a>
            <a href="#" @click="selectLoginUser(true)" class="cardusersTab" :class="{
              active: loginUserSelected,
              'border-beneficiaries': loginUserSelected
            }">{{ $t("title.cardUsersLabel") }}</a>
          </div>

          <div :class="[
            !loginUserSelected
              ? 'wrapper-login-companies'
              : 'wrapper-login-beneficiaries'
          ]">
            <div :class="[
              !loginUserSelected
                ? 'wrapper-login-white-bar-companies'
                : 'wrapper-login-white-bar-beneficiaries'
            ]"></div>
            <div class="logo">
              <img src="../../assets/images/up.svg" />
              <p>{{ $t("title.loginTitle") }}</p>
              <p class="loginError" v-if="this.loginError != null" v-html="this.getErrorMessage()"></p>
              <p v-else><br /></p>
            </div>

            <div class="loginForm">
              <form v-if="!otpFlow" @submit.prevent="handleSubmit">
                <input type="text" :placeholder="[
                  [
                    this.loginUserSelected
                      ? $t('title.cardUserFieldLabel')
                      : $t('title.userFieldLabel')
                  ]
                ]" id="input-2" v-model="formData.username" required="true" />
                <div class="password">
                  <input :placeholder="[[$t('title.passwordFieldLabel')]]" id="input-3" v-model="formData.password"
                    :type="showPassword ? 'text' : 'password'" required />
                  <i v-on:click="showPassword = !showPassword">
                    <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" />

                  </i>
                </div>
                <vue-turnstile site-key="0x4AAAAAAAw3AgPjWhVENVKk" v-model="recaptchaToken" theme="light" :resetInterval="1000 * 1000"  class="d-flex justify-content-center mb-2" />
                <p class="text-right small">
                  <router-link v-if="loginUserSelected" to="/forgot_password">
                    {{ $t("title.forgotPasswordLink") }}
                  </router-link>
                  <router-link v-else to="/forgot_password_company">
                    {{ $t("title.forgotPasswordLink") }}
                  </router-link>
                </p>
                <input type="submit" :value="[[$t('title.loginButtonLabel')]]" variant="primary"
                  class="submitCompanies" />
                <p class="text-right small cursor-pointer pt-2">
                  <router-link v-show="!loginUserSelected" to="/create_account_company">
                    {{ $t("title.createAccountLink") }}
                  </router-link>
                  <!-- <a v-show="!loginUserSelected" style="cursor: pointer"
                    @click="showModalPopup('modal_popup_help', true)">{{ $t("title.loginHelpLink") }}</a> -->
                  <router-link v-show="loginUserSelected" to="/create_account">
                    {{ $t("title.createAccountLink") }}
                  </router-link>
                </p>
              </form>
              <form v-else @submit.prevent="sendOtp">
                <input type="text" :placeholder="[[$t('title.otpField')]]" id="input-otp" v-model="otp_password"
                  required />
                <div class="form-check">
                  <input type="checkbox" id="rememberMe" v-model="rememberMe" class="form-check-input" />
                  <label for="rememberMe">
                    {{ $t("title.rememberMeText") }}
                  </label>
                </div>
                <p class="text-right">
                  <a href="#" class="link" @click="resendOtp()">{{
                    $t("title.otpResendText")
                  }}</a>
                </p>
                <p v-if="otpResendSuccess" class="lightGrey text-center">
                  {{ $t("title.msgEmailSuccess") }}
                </p>
                <input type="submit" :value="[[$t('title.confirmButtonLabel')]]" variant="primary"
                  class="submitCompanies" />
              </form>
            </div>
          </div>
        </div>
        <!--End Login box -->

        <!--Slider container -->
        <div class="sliderContainer">

        <carousel v-if="!loginUserSelected" class="banner" :items-to-show="1"  :wrap-around="true" 
        :autoplay="companiesBanners > 1 ? 20000 : 0">
            <slide v-for="(entry, index) in companiesBanners" :key="index">
              <!-- <template v-slot:img> -->
                <a :href="entry.link" target="_blank" @click="
                  sendClickToGA(entry.category, entry.action, entry.label)
                  ">
                  <img :src="require('@/assets/images/banner/' + entry.fileName)" />
                </a>
              <!-- </template> -->
            </slide>

            <template #addons>
              <navigation />
            </template>
          </carousel>
          <carousel v-if="loginUserSelected" class="banner"  :items-to-show="1"  :wrap-around="true" 
          :autoplay="beneficiariesBanners > 1 ? 20000 : 0">
            <slide v-for="(entry, index) in beneficiariesBanners" :key="index">
              <!-- <template v-slot:img> -->
                <a :href="entry.link" target="_blank" @click="
                  sendClickToGA(entry.category, entry.action, entry.label)
                  ">
                  <img :src="require('@/assets/images/banner/' + entry.fileName)" />
                </a>
              <!-- </template> -->
            </slide>

            <template #addons>
              <navigation />
            </template>
          </carousel>
          </div>

        </div>
      </div>
      <div class="text-center">
        <p>
          <span>{{ $t("title.downloadAppMessage") }}</span>
        </p>
        <p>
          <a href="https://apps.apple.com/ro/app/up-mobil-moldova/id1542085076?l=ro">
            <img src="../../assets/images/appstore.png" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.solde.md">
            <img src="../../assets/images/gplay.png" />
          </a>
        </p>
      </div>
    <div id="modal_popup_help" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_help', false)" class="close-button">
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('title.customerHelpPopup')"></p>
      </div>
    </div>
    <div id="modal_popup_login_user" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_login_user', false)" class="close-button">
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('title.userLoginHelpPopup')">
        </p>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="../../assets/images/loading.gif" />
        <p v-html="$t('title.loadingPopupMessage')">
        </p>
      </div>
    </div>
    <div id="modal_popup_error" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_error', false)" class="close-button">
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('title.errorPopupMessage')">
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped src="@/assets/styles/login.css"></style>

<script>
// import LanguageBar from "@/components/LanguageBar.vue";
import httpService from "../../http/http-common.js";
import $ from "jquery";
import Cookies from "js-cookie";
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import loginBanner from "@/assets/json/login_banner.js";
import { v4 as uuidv4 } from 'uuid';
  import VueTurnstile from 'vue-turnstile';

import utils from '@/utils.js'

export default {
  name: "Login",
  setup() {

    const { showModalPopup, getUrlParam } = utils()

    return {
      showModalPopup,
      getUrlParam,
    }
  },
  props: {
    msg: String
  },
  components: {
    // LanguageBar,
    VueTurnstile,
    Carousel,
    Slide,
    Navigation
    // Chat
  },
  data() {
    return {
      formData: {
        username: "",
        password: ""
      },
      showPassword: false,
      loginUserSelected: true,
      loginError: null,
      options: {
        expires: 10,
        sameSite: "Strict"
      },
      recaptchaToken: null,
      recaptchaKey: "0x4AAAAAAAw3AgPjWhVENVKk",
      companiesBanners: loginBanner[0].Companies,
      beneficiariesBanners: loginBanner[0].Beneficiaries,
      formStyleScope: 'normal',
      otpFlow: false,
      otp_password: null,
      otpResendSuccess: false,
      rememberMe: false,
      uuid: null,
      existsToken: false,
      modalVisible: true,
    };
  },
  methods: {
    async handleSubmit() {
      if (this.loginUserSelected) {
        this.loginBeneficiary();
      } else {
        // if customer or affiliate is selected
        // show loading
        this.loginCustomer();
      }
        },
    selectLoginUser(isSelectBeneficiaryLogin, fromRouter = false) {
      this.formData.username = "";
      this.formData.password = "";
      this.loginError = null;
      if (!this.otpFlow) {
        this.loginUserSelected = isSelectBeneficiaryLogin;
      }
    },
    async loginBeneficiary() {
      localStorage.setItem("uuid", this.uuid);
      httpService.beneficiaryService
        .userLogin(this.formData, this.recaptchaToken, localStorage.getItem("uuid"))
        .then(function (response) {
          // handle success
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            let beneficiaryLink = response.headers["link"].split(";")[0];
            beneficiaryLink = beneficiaryLink.replace(">", "");
            beneficiaryLink = beneficiaryLink.replace("<", "");
            Cookies.set("beneficiaryLink", beneficiaryLink, {
              expires: response.data.expires_in / (24 * 60 * 60),
              sameSite: "Strict"
            });
            Cookies.set("token", response.data.access_token, {
              expires: response.data.expires_in / (24 * 60 * 60),
              sameSite: "Strict"
            });
            if (response.headers["x-up-captcha"] === "required") {
              this.otpFlow = true;
            } else {
              this.$router.push({
                name: "beneficiary"
              });
            }

          } else if (response.status == 400 || response.status == 403) {
            this.loginError = {
              code: response.data.error,
              description: response.data.error_description
            };
          } else {
            this.loginError = {
              code: "server_error",
              description: response.description
            };
          }
          this.showModalPopup("modal_popup_loading", false);

        })
        .catch(error => {
          // handle error
          if (error.response.status == 400 || error.response.status == 403) {
            this.loginError = {
              code: error.response.data.error,
              description: error.response.data.error_description
            };
          } else {
            this.loginError = {
              code: error.response.status,
              description: error.response.data
            };
            return error.response;
          }
          this.showModalPopup("modal_popup_loading", false);

        });
    },

    loginCustomer() {
      httpService.companyService
        .userLogin(this.formData, this.recaptchaToken)
        .then(response => {
          let userLink = response.headers["link"].split(";")[0];
          userLink = userLink.replace(">", "");
          userLink = userLink.replace("<", "");

          Cookies.set("userLink", userLink, {
            expires: response.data.expires_in / (24 * 60 * 60),
            sameSite: "Strict"
          });
          Cookies.set("token", response.data.access_token, {
            expires: response.data.expires_in / (24 * 60 * 60),
            sameSite: "Strict"
          });

          setTimeout(() => {
            window.location = '/company';
          }, 500)
        })
        .catch(error => {
          // handle error
          if (error.response === undefined || !error.response) {
            this.loginError = {
              code: error.response ? error.response.data.error : "500",
              description: error.response
                ? error.response.statusText
                : "server_error"
            };
          } else if (
            error.response.status == 400 ||
            error.response.status == 403
          ) {
            this.loginError = {
              code: error.response.data.error,
              description: error.response.data.error_description
            };
          } else {
            this.loginError = {
              code: error.response ? error.response.data.error : "500",
              description: error.response
                ? error.response.statusText
                : "server_error"
            };
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },

    checkLoginPage() {
      if ($("body").hasClass("login-pages")) {
        $("body").removeClass("login-pages");
      }
    },
    getErrorMessage() {
      if (this.loginError.description) {
        try {
          let description = JSON.parse(this.loginError.description);
          if (description) {
            let params = description[Object.keys(description)[0]];
            if (params.length > 1) {
              return this.$t("title.errorMessage_" + Object.keys(description)[0], {
                param: params[0],
                param1: params[1]
              });
            } else {
              return this.$t("title.errorMessage_" + Object.keys(description)[0], {
                param: params
              });
            }

            return this.$t("title.errorMessage_" + Object.keys(description)[0], {
              param: params
            });
          } else {
            return this.$t("title.errorMessage_" + this.loginError.code);
          }
        } catch {
          return this.$t("title.errorMessage_" + this.loginError.code);
        }
      } else {
        return this.$t("title.errorMessage_" + this.loginError.code);
      }
    },
    sendClickToGA(category, action, label) {
      if (this.$ga) {
        this.$ga.event({
          eventCategory: category,
          eventAction: action,
          eventLabel: label
        });
      }
    },
    resendOtp() {
      let otpData = {
        resend: true,
      };
      this.otpResendSuccess = false;

      let headers = {
        Authorization: "Bearer " + btoa(Cookies.get("token")),
        "Content-Type": "application/json",
      };

      if (this.rememberMe) {
        headers = {
          ...headers,
          "X-Up-Device": this.uuid
        }
      }

      httpService.beneficiaryService
        .sendOtp(otpData, headers)
        .then((response) => {
          if (response.status == 200) {
            this.otpResendSuccess = true;
          } else if (response.status == 400 || response.status == 403) {
            this.loginError = {
              code: response.data.error_code,
              description: response.data.description,
            };
          } else {
            this.loginError = { description: response.code };
          }
        })
        .catch((error) => {
          return error.response;
        });
    },
    sendOtp() {
      let otpData = {
        otp: this.otp_password,
        trusted: this.rememberMe
      };

      let headers = {
        Authorization: "Bearer " + btoa(Cookies.get("token")),
        "Content-Type": "application/json",
      };

      if (this.rememberMe) {
        headers = {
          ...headers,
          "X-Up-Device": this.uuid
        }
      }

      httpService.beneficiaryService
        .sendOtp(otpData, headers)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.valid === true) {
              this.$router.push({ name: "beneficiary" });
            } else {
              this.loginError = {
                code: "otp",
                description: response.data.description,
              };
            }
          } else if (response.status == 400 || response.status == 403) {
            this.loginError = {
              code: response.data.error_code,
              description: response.data.description,
            };
          } else {
            this.loginError = { description: response.code };
          }
        })
        .catch((error) => {
          return error.response;
        });
    },
  },

  mounted() {

  },
  created() {
    // this.showModalPopup('modal_popup_error', true);
    this.formStyleScope = (this.$route.meta && this.$route.meta.styleScope) ? this.$route.meta.styleScope : 'normal';
    this.checkLoginPage();
    let category = this.getUrlParam("category");
    if (category !== null) {
      if (category === "8") {
        this.selectLoginUser(true);
      } else {
        this.selectLoginUser(false);
      }
    } else {
      if (this.$route.params.loginUserSelected !== null) {
        this.selectLoginUser(this.$route.params.loginUserSelected, true);
      }
    }
    let serverError = this.getUrlParam("serverError");
    if (serverError !== null) {
      this.loginError = {
        code: "server_error",
        description: "Server Error"
      };
    }
    Cookies.remove("selectedCompany");
    Cookies.remove("token");
    Cookies.remove("userLink");
    Cookies.remove("first");
    localStorage.removeItem("customer_data");
    localStorage.removeItem("affiliate_data");
    if (localStorage.getItem("uuid")) {
      this.uuid = localStorage.getItem("uuid");
    } else {
      this.uuid = uuidv4();
    }
  }
};
</script>
