import "vue-form-generator/dist/vfg-core.css";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import "bootstrap/dist/js/bootstrap.js";
import 'vue3-carousel/dist/carousel.css'
import "./assets/styles/fonts.css";
import { createApp } from "vue";
import router from "./router.js";
import store from "./store.js";

// import VueGtm from "vue-gtm";
import VueAnalytics from "vue-analytics";
// import qs from 'qs'
import axios from "axios"
import VueAxios from 'vue-axios'
import { BootstrapVue3 } from 'bootstrap-vue-3'

import Cookies from "js-cookie";
// import auth from "@/auth";


import i18n from './plugins/language/i18n.js';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faInfoCircle,
	faCalendarAlt,
	faChevronLeft,
	faPlusCircle,
	faMinusCircle,
	faCheckCircle,
	faUserPlus,
	faUserMinus,
	faArrowAltCircleRight,
	faTrashAlt,
	faEnvelope,
	faPrint,
	faChevronDown,
	faUniversity,
	faEyeSlash,
	faEye
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Pagination from "./components/fragments/Pagination";

library.add(faInfoCircle);
library.add(faCalendarAlt);
library.add(faChevronLeft);
library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faCheckCircle);
library.add(faUserPlus);
library.add(faUserMinus);
library.add(faArrowAltCircleRight);
library.add(faTrashAlt);
library.add(faEnvelope);
library.add(faPrint);
library.add(faChevronDown);
library.add(faUniversity);
library.add(faEyeSlash);
library.add(faEye);

// require("./assets/styles/normalize.css");
require("./http/http-common.js");

import App from "./App.vue";
const app = createApp(App)

// app.mixin({
// 	methods: {
// 		titleCase(value){
// 			if (!value) return "";
// 			value = value
// 				.toLowerCase()
// 				.replace("-", " ")
// 				.split(" ");
// 			for (var i = 0; i < value.length; i++) {
// 				value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
// 			}
// 			return value.join(" ");
// 		},
		
// 	},
// });

if (!FileReader.prototype.readAsBinaryString) {
	FileReader.prototype.readAsBinaryString = function (fileData) {
		var binary = "";
		var pt = this;
		var reader = new FileReader();
		reader.onload = function () {
			var bytes = new Uint8Array(reader.result);
			var length = bytes.byteLength;
			for (var i = 0; i < length; i++) {
				binary += String.fromCharCode(bytes[i]);
			}
			//pt.result  - readonly so assign binary
			pt.content = binary;
			pt.onload();
		};
		reader.readAsArrayBuffer(fileData);
	};
}

import JsonExcel from "vue-json-excel3";

	app.use(router)
	.use(store)
	.use(VueAxios, axios)
	.use(BootstrapVue3)
	.use(i18n())
	.component("font-awesome-icon", FontAwesomeIcon)
	.component("listPagination", Pagination)
	.component("downloadExcel", JsonExcel)
	.mount('#app');


