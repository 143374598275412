<template>
  <div>
    <article>
      <div class="reccomend">
        <b-container fluid class="border-box">
          <b-row class="border-box">
            <b-col class="border-box" xl="6" md="6">
              <div v-if="this.productsByMobileNumber" v-for="(mobilePhone, index) in this.productsByMobileNumber"
                :key="index" class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/support.svg" />
                </div>
                <div>
                  <strong v-for="(product, index) in mobilePhone" :key="index">
                    <span v-if="index === mobilePhone.length - 1">{{
                      product
                    }}</span>
                    <span v-else>{{ product }}, </span>
                  </strong>
                  <br />{{
                    getPersonByMobilePhone(index).last_name +
                    " " +
                    getPersonByMobilePhone(index).first_name
                  }}
                  <br />{{ getPersonByMobilePhone(index).mobile_phone }}
                  <br />{{ getPersonByMobilePhone(index).email }}
                </div>
              </div>
              <div class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/location.svg" />
                </div>
                <div>
                  <!-- <strong>{{ $t("title.registeredAddressTitle") }}</strong>
                  <br>{{ $t("title.registeredAddressText1") }}
                  <br>{{ $t("title.registeredAddressText2") }}
                  <br>
                  <br> -->
                  <strong>{{ $t("title.mailingAddressTitle") }}</strong>
                  <br>{{ $t("title.mailingAddressText1") }}
                  <br>{{ $t("title.mailingAddressText2") }}
                </div>
              </div>
              <div class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/phone.svg" />
                </div>
                <div>
                  <a href="tel:022.024.020">022.024.020</a>
                </div>
              </div>

              <div class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/chat.svg" />
                </div>
                <div>
                  <a href="mailto:infomoldova@upmoldova.md">infomoldova@upmoldova.md </a>
                </div>
              </div>

              <div class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/phonechat.svg" />
                </div>
                <div>{{ $t("title.workingHours") }}</div>
              </div>

              <!-- <div class="contact-item">
                <div class="icon">
                  <img src="@/assets/images/CRAVATA.svg" />
                </div>
                <div>
                  {{ $t("title.commerceRegisterID") }}:
                  <br />J40/6468/2002 <br />{{ $t("title.vat") }}: RO 14774435 <br />Capital
                  social: 22.486.460 lei
                  <br />
                </div>
              </div> -->
            </b-col>
            <!-- <b-col class="border-box" offset-xl="1" offset-md="1" xl="5" md="5">
              <div class="feedback-form">
                <div class="feedback-form-title">
                  <h3>{{ $t("title.feedbackFormTitle") }}</h3>
                </div>
                <div v-if="sentFeedbackError === null">
                  <p v-if="!successSentFeedback">
                    <textarea class="feedback-input" v-model="feedbackMessage"
                      :placeholder="[[$t('title.placeholderComment')]]" />
                  </p>
                  <a v-if="!successSentFeedback" class="button-orange" @click="sendEmailFeedback()">{{
                    $t("title.textButtonForm") }}</a>
                  <p v-else>{{ $t("title.msgEmailSuccess") }}</p>
                </div>
                <div v-else>
                  <p v-if="this.sentFeedbackError" v-html="$t('title.errorActionBeneficiary_500')"></p>
                </div>
              </div>
            </b-col> -->
          </b-row>
        </b-container>

        <b-container fluid class="border-box">
          <b-row class="border-box"> </b-row>
        </b-container>

        <br />
        <br />
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.3868826801977!2d28.78909797636068!3d47.03263867114301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97deee0254351%3A0x33135aeb9cf287a!2sStrada%20Doina%20%C5%9Fi%20Ion%20Aldea-Teodorovici%2022%2C%20Chi%C8%99in%C4%83u%202051%2C%20Moldova!5e0!3m2!1sro!2sro!4v1705053305148!5m2!1sro!2sro"
        style="border:0" width="100%" height="300" frameborder="0"></iframe>
      </div>
    </article>
  </div>
</template>
<script>
import { BContainer, BRow, BCol } from "bootstrap-vue-3";
import Cookies from "js-cookie";
import httpServiceCustomer from "@/http/http-customer.js";

export default {
  props: {
    affiliate: Object
  },
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol
  },
  data() {
    return {
      feedbackMessage: null,
      successSentFeedback: false,
      sentFeedbackError: null
    };
  },
  methods: {
    getPersonByMobilePhone(mobilePhone) {
      for (let index in this.affiliate.products) {
        if (
          this.affiliate.products[index].person.mobile_phone === mobilePhone
        ) {
          return this.affiliate.products[index].person;
        }
      }
    },
    sendEmailFeedback() {
      let formData = {
        code: this.affiliate.company_id,
        description: this.feedbackMessage
      };
      httpServiceCustomer.customerService
        .sendFeedbackMail(formData, Cookies.get("token"), "AFF")
        .then(function (response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.successSentFeedback = true;
            // download content
          } else if (response.status == 400 || response.status == 403) {
            this.sentFeedbackError = response.response;
          } else if (response.status == 404) {
            // show file not found
            this.sentFeedbackError = response.response;
          }
        })
        .catch(error => {
          this.sentFeedbackError = {
            code: error.response.status
          };
        });
    }
  },
  computed: {
    productsByMobileNumber() {
      let returnProducts = Object.create(null);
      this.affiliate.products.forEach(p => {
        if (p.person) {
          (returnProducts[p.person.mobile_phone] =
            returnProducts[p.person.mobile_phone] || []).push(p.name)
        }
      });
      return returnProducts;
    }
  },
  mounted() { }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
