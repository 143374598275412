<template>
  <div>
    <!-- St1ickers -->
    <br />
    <h2 class="section-title">{{ $t("title.stickersTitleSection") }}</h2>
    <div class="container-alb">
      <div class="section-table filter-mobile">
        <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
          <tr>
            <td valign="middle">
              <h3>{{ $t("title.stickersInfo") }}</h3>
            </td>
          </tr>
          <tr>
            <td valign="middle" colspan="2">
              <div class="d-inline-block" v-for="(mediumSticker, index) in stickers[0].medium" :key="index">
                <div class="sticker-wrapper" v-if="affiliateHasCardProduct(mediumSticker.products[0])">
                  <img class="sticker-image" :src="require('@/assets/images/affiliate-stickers/' +
                    mediumSticker.file_name)
                    " />
                </div>
              </div>
            </td>
          </tr>
        </table>
        <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
          <tr v-if="affiliateHasCardProduct(11)">
            <td valign="middle">
              <h3 class="m-0">{{ $t("title.smallStickersLabel") }}
                <input type="number" name="quantity" class="quantity-input" v-model="smallStickerAmount" />
                {{ $t("title.quantityStickers") }}
              </h3>
            </td>
          </tr>
          <tr v-if="affiliateHasCardProduct(12)">
            <td valign="middle">
              <h3 class="m-0">{{ $t("title.bigStickersLabel") }}
                <input type="number" name="quantity" class="quantity-input" v-model="bigStickerAmount" />
                {{ $t("title.quantityStickers") }}
              </h3>
            </td>
          </tr>
        </table>
        <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
          <tr>
            <td valign="middle">
              <form @submit.prevent="
              ">
                <strong>{{ $t("title.deliveryAddressStickers") }}</strong>
                <select class="filtrare" @change="subsidiarySelected">
                  <option selected :value="0">{{ $t("title.selectLabel") }}</option>
                  <option v-for="(entry, index) in this.workingPointAddress" :key="index" :value="entry.codcom"
                    :selected="isSelectedSubsidiary(entry.codcom)">{{ customLabel(entry) }}</option>
                </select>
              </form>
            </td>
            <td valign="middle" align="right">
              <a class="button-orange" @click="sendStrickers()">{{
                $t("title.textButtonForm")
              }}</a>
            </td>
          </tr>
        </table>
      </div>
      <div class="section-table filter-mobile">
        <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
          <tr>
            <td valign="middle">
              <!-- <h3 class="mt-0">{{ $t("title.digitalStickersLabel") }}</h3> -->
              <h3>{{ $t("title.digitalStickersText") }} </h3>

              <div class="d-inline-block" v-for="(elSticker, index) in stickers[0].electronic" :key="index">
                <a v-if="affiliateHasCardProduct(elSticker.products[0])" :href="require('@/assets/images/affiliate-stickers/' + elSticker.file_name)"  target="_blanc" class="cursor-pointer sticker-wrapper">
                  {{ elSticker.description}}</a>
                  <br />
              </div>

            </td>
          </tr>
        </table>
      </div>
    </div>

    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <b>
          <p v-if="this.sentStickersError" v-html="$t('title.stickersError_' + this.sentStickersError.code)"></p>
          <p v-else-if="this.sentStickers">{{ $t("title.stickersSentSuccess") }}</p>
        </b>
      </div>
    </div>

    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('title.loadingPopupMessage')"></p>
      </div>
    </div>

  </div>
</template>

<script>
import Cookies from "js-cookie";
import stickers from "@/assets/json/stickers.js";
import httpServiceAffiliate from "@/http/http-affiliate.js";
import httpServiceUser from "@/http/http-user.js";
import {
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue-3";

export default {
  props: {
    affiliate: Object,
    msg: String
  },
  components: {
    "b-form-checkbox": BFormCheckbox,
    "b-form-checkbox-group": BFormCheckboxGroup,
  },
  data() {
    return {
      stickers: stickers,
      selectedStickers: [],
      error: null,
      subsidiaries: [],
      sentStickers: false,
      sentStickersError: null,
      otherStickerText: "",
      workingPointAddress: [],
      filters: {
        subsidiary: this.affiliate.company_id
      },
      isLoading: false,
      smallSticker: false,
      smallStickerAmount: 0,
      bigStickerAmount: 0,
    };
  },
  methods: {
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    subsidiarySelected(event) {
      this.filters.subsidiary = event.target.value;
    },
    isSelectedSubsidiary(subsidiary) {
      return subsidiary === this.filters.subsidiary;
    },
    affiliateHasCardProduct(entry) {
      let hasProduct = false;
        this.affiliate.products.forEach((prod) => {
          if (parseInt(prod.card) === parseInt(entry)) {
            console.log(prod.card);
            console.log(entry);
            hasProduct = true;
          }
        });
        return hasProduct;
      },
    getAddressPoints(env, brand) {
      this.environment = env;
      this.isLoading = true;
      var params =
        "?type=affiliate" +
        "&brand=" +
        brand +
        "&environment=" +
        this.environment +
        "&per_page=10000&page=1";

      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          params += "&invoice_group=1";
        }
      }

      httpServiceUser.userService
        .getAddressPoints(
          this.affiliate.company_id,
          params,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            this.subsidiaries = response.data;
            this.isLoading = false;
            this.subsidiaries.forEach(x => {
              this.workingPointAddress.push({
                codcom: x.codcom,
                address: x.address.street_name + ", " + x.address.locality
              });
            });
          } else if (response.status == 400 || response.status == 403) {
            return null;
          } else {
            return null;
          }
        })
        .catch(error => {
          return null;
        });
    },
    getStickersForSubmit() {
      var returnStickers = [];
      if (this.smallStickerAmount > 0)
        returnStickers.push({
          id: "autocolant up dejun",
          description: "Autocolant Up Dejun",
          amount: this.smallStickerAmount
        });
      if (this.bigStickerAmount > 0)
        returnStickers.push({
          id: "autocolant up cadou",
          description: "Autocolant Up Cadou",
          amount: this.bigStickerAmount
        });
      return returnStickers;
    },
    sendStrickers() {
      if (this.smallStickerAmount === 0 && this.bigStickerAmount === 0) {
        this.sentStickersError = {code: "NO_STICKERS"}
        this.showModalPopup("modal_popup_info", true);
        return;
      }
      var formData = {
        affiliate_id: this.filters.subsidiary,
        details: this.getStickersForSubmit()
      };
      this.sentStickersError = null;
      this.sentStickers = false;

      this.showModalPopup("modal_popup_loading", true);

      httpServiceAffiliate.affiliateService
        .sendStickers(this.affiliate.company_id, formData, Cookies.get("token"))
        .then(response => {
          if (response.status == 200) {
            this.sentStickers = true;
          } else if (response.status == 400 || response.status == 403) {
            this.sentStickersError = { code: "500" };
          } else {
            this.sentStickersError = { code: "500" };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        })
        .catch(error => {
          this.sentStickersError = { code: "500" };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    customLabel({ codcom, address }) {
      return `${codcom} – ${address}`;
    }
  },
  mounted() {
    this.getAddressPoints("both", this.affiliate.products[0].brand);
  }
};
</script>

<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
.sticker-wrapper {
  background-color: #f1f1f1;
  padding: 10px;
  display: inline-block;
  width: auto;
  margin: 10px;
}

.sticker-image {
  display: block;
  margin-bottom: 10px;
  max-width: 400px;
}

.quantity-checkbox {
  display: inline-block;
  width: auto;
}

.quantity-input,
input[type="number"].quantity-input {
  display: inline-block;
  width: 30px;
  padding: 0 3px;
  height: auto;
  margin: 0 10px 0 15px;
}

.sticker-wrapper .custom-control-input:checked~.custom-control-label::before {
  background-color: #4e5b59;
}
</style>
